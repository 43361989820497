<template>
    <v-container fluid>
      <default-ula />
      <v-row justify="space-around">
          <v-card max-width="500px">
            <v-list>
              <v-list-item>
                <v-list-item-content>
                  <v-flex xs12 sm12 md12>
                    <v-text-field
                      md12
                      v-model="modelPassword.old"
                      label="Текущий пароль"
                      name="oldpassword"
                      :append-icon="showPassword.old ? 'mdi-eye' : 'mdi-eye-off'"
                      :rules="[rules.required, rules.password]"
                      :type="showPassword.old ? 'text' : 'password'"
                      @click:append="showPassword.old = !showPassword.old"
                    ></v-text-field>
                    <v-text-field
                      md12
                      v-model="modelPassword.new"
                      label="Новый пароль"
                      name="password"
                      :append-icon="showPassword.new ? 'mdi-eye' : 'mdi-eye-off'"
                      :rules="[rules.required, rules.password]"
                      :type="showPassword.new ? 'text' : 'password'"
                      @click:append="showPassword.new = !showPassword.new"
                    ></v-text-field>
                    <v-text-field
                      md12
                      v-model="modelPassword.confirm"
                      label="Подтверждение пароля"
                      name="confirmpassword"
                      :append-icon="
                        showPassword.confirm ? 'mdi-eye' : 'mdi-eye-off'
                      "
                      :rules="[rules.required, rules.password]"
                      :type="showPassword.confirm ? 'text' : 'password'"
                      @click:append="showPassword.confirm = !showPassword.confirm"
                    ></v-text-field>
                  </v-flex>
                  <v-card-actions class="justify-center">
                    <v-btn
                    :to="back">Назад</v-btn>
                    <v-btn color="blue darken-1" text @click="reset">
                      Сбросить
                    </v-btn>
                    <v-btn color="blue darken-1" text @click="save">
                      Сохранить
                    </v-btn>
                  </v-card-actions>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </template>
  <script>
  import { get, sync } from "vuex-pathify";
  import { mapActions } from "vuex";
  import { rules } from "@/util/rules";
  import { format } from "date-fns";
  
  export default {
    name: "ChangePass",
    components: {
      DefaultUla: () => import("@/components/ULA.vue")
    },
    data() {
      return {
        rules,
        modelPassword: {
          old: "",
          new: "",
          confirm: ""
        },
        showPassword: {
          old: false,
          new: false,
          confirm: false
        },
        back: "/app/user/profile/",
        user: {}
      };
    },
  
    computed: {
      profile: get("auth/profile"),
      options: get("reference/optionsUser"),
      filteredKeys() {
        return this.keys.filter(key => key !== "Name");
      }
    },
    methods: {
      format,
      ...mapActions({
        getProfile: "auth/getProfile",
        notification: "auth/notification"
      }),
      save() {
        const checkEmptyPasswords =
          !!this.modelPassword.old &&
          !!this.modelPassword.new &&
          !!this.modelPassword.confirm;
        const checkEqualPassword =
          this.modelPassword.new == this.modelPassword.confirm;
        if (!checkEmptyPasswords) {
          this.notification("Пожалуйста, заполните все поля.");
        } else if (!checkEqualPassword) {
          this.notification(
            "Новый пароль и подтверждение пароля должны совпадать!"
          );
        } else {
          this.$http
            .post("api/account/user/password", {
              currentPassword: this.modelPassword.old,
              newPassword: this.modelPassword.new
            })
            .then(response => {
              if (response.status === "Ok") {
                this.user.firstLogon = false;
                localStorage.setItem("user", JSON.stringify(this.user))
                this.notification(response.text);
              } 
            });
        }
      },
      reset() {
        this.modelPassword.old = "";
        this.modelPassword.new = "";
        this.modelPassword.confirm = "";
      },
    },
    created() {
      this.user = JSON.parse(localStorage.getItem("user"));
    }
  };
  </script>
  